<template>
  <div
    class="bleach-expander"
    :class="{
      'bleach-expander--animated': true,
      'bleach-expander--animated--collapsed': !expandedData
    }"
    ref="expander"
    :style="{
      opacity: expandedData ? 1 : 0,
      height: expandedData
        ? expandedHeight && !isIdle
          ? expandedHeight + 'px'
          : 'auto'
        : '0'
    }"
  >
    <div ref="container">
      <slot />
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex"
export default {
  props: {
    expanded: Boolean
  },
  data: () => ({
    expandedHeight: null,
    isIdle: false,
    expandedData: false
  }),
  mounted() {
    this.init()
  },
  methods: {
    onExpandedIdle(evt) {
      if (evt.propertyName == "height") {
        this.isIdle = true
        this.$refs["expander"].removeEventListener(
          "transitionend",
          this.onExpandedIdle
        )
      }
    },
    doCollapse() {
      this.expandedHeight = this.$refs["container"].clientHeight
      this.$nextTick(() => {
        this.isIdle = false
        const isReadyToCollapse = setTimeout(() => {
          if (this.$refs["expander"].style.height !== "auto") {
            this.expandedData = false
            clearTimeout(isReadyToCollapse)
          }
        }, 2)
      })
    },
    doExpand() {
      this.$refs["expander"].addEventListener(
        "transitionend",
        this.onExpandedIdle
      )
      this.expandedHeight = this.$refs["container"].clientHeight
      this.$nextTick(() => {
        this.expandedData = true
      })
    },
    init() {
      if (this.expanded) this.doExpand()
    }
  },
  // computed: {
  //   ...mapState("breakpoints", ["currentWidth"])
  // },
  watch: {
    expanded(newVal) {
      if (!newVal) {
        this.doCollapse()
      } else {
        this.doExpand()
      }
    }
  }
}
</script>

<style lang="scss">
.bleach-expander {
  overflow: hidden;
  margin: 0 -1px; // Added to allow descendant input fields to have fully visible 'focus' outlines
  padding: 0 1px;
  &--animated {
    transition: height 0.2s ease 0.1s, margin 0.2s ease 0.1s,
      opacity 0.2s linear 0.3s;
    &--collapsed {
      transition: height 0.2s ease 0.1s, margin 0.2s ease 0.1s,
        opacity 0.1s linear;
    }
  }
}
</style>
