var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"expander",staticClass:"bleach-expander",class:{
    'bleach-expander--animated': true,
    'bleach-expander--animated--collapsed': !_vm.expandedData
  },style:({
    opacity: _vm.expandedData ? 1 : 0,
    height: _vm.expandedData
      ? _vm.expandedHeight && !_vm.isIdle
        ? _vm.expandedHeight + 'px'
        : 'auto'
      : '0'
  })},[_c('div',{ref:"container"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }